function initThemeSwitcher() {
  // Function to set the theme and update checkbox state
  function setTheme(theme) {
    document.documentElement.setAttribute("data-theme", theme);
    checkbox.checked = theme === "dark";
    // Store the theme preference in local storage
    localStorage.setItem("theme", theme);
  }

  let checkbox = document.querySelector("input[name=theme_switch]");

  // Check if there's a theme preference in local storage
  const storedTheme = localStorage.getItem("theme");

  // Set the theme based on the stored preference or the user's preference
  if (storedTheme) {
    setTheme(storedTheme);
  } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    setTheme("dark");
  } else {
    setTheme("light");
  }

  // Switch theme if checkbox is engaged
  checkbox.addEventListener("change", (cb) => {
    const newTheme = cb.target.checked ? "dark" : "light";
    setTheme(newTheme);
  });
}

// Call the magic function to initialize the theme switcher
initThemeSwitcher();
